body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.opaque {
  opacity: 0.85;
}

i {
  transition: color ease-in-out 0.3s;
}

i:hover {
  color: #555555;
  cursor: pointer;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  border-radius: 100px;
  background-color: #2142b2;
}

.nav-pills {
  justify-content: center;
}

.nav-pills .nav-item {
  margin: 0px 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}
