.NavBar {
  background-color: #ffffff;
  border: 1px solid #dadadf;
  z-index: 9999;
}

.NavLink {
  padding: 5px;
}

.Active i {
  color: #555555;
}
